/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // helper functions



  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $(function () {

          var $window = $(window)
          var $h1 = $('p')
          var $res = $('.res')
          var $size = $('.size')

          $window.on('resize', function (data) {
            $res.text($window.width())
            $size.text($h1.css('fontSize'))
          }).trigger('resize')

        })
        function hideNearestPrintIcon(formId) {
          $('#gform_confirmation_wrapper_' + formId).siblings('h3').children('span').attr("style", "display: none !important");

        }
        function toggleSelected(target, select) {

          $('#forms-tabs li.selected').removeClass('selected');
          $('#forms-tabs li[data-target="'+target+'"]').addClass('selected');

          $('#forms-container div.selected').removeClass('selected');
          $('#forms-container div#'+target).addClass('selected');
          if(select == true) {
            $('.mobile-forms-selector').val(target);
            $('.mobile-forms-selector').trigger('change');
          }
        }
        jQuery(document).on('gform_confirmation_loaded', function(event, formId){
            hideNearestPrintIcon(formId);
        });
        $('.mobile-forms-selector').select2();

        $('.mobile-forms-selector').on('select2:select', function (e) {
          var target = e.params.data.id
          toggleSelected(target);
        });

        $('#forms-tabs li a').on('click', function(e) {
          e.preventDefault();
          var target = $(this).parent().data('target');

          toggleSelected(target, true);
        });

        $("#userBuilding").text($('#userLocationText').text());
        /*
        // moved this to vanilla js in base.php in order to deal with Firefox cache issue of onload
        //$(window).load(function(){
        $(window).on('load', function() {
          console.log("window loaded");
          $('.theme-wrap').addClass('show-theme');
        });
        */

        $('.cbg1 input').on('change', function() {
          $('.cbg2 input').not(this).prop('checked', false);
        });
        $('.cbg2 input').on('change', function() {
          $('.cbg1 input').not(this).prop('checked', false);
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'page_template_template_login': {
      init: function() {
        $('.login-remember').on('click', function(e) {
          e.preventDefault();
          $(this).toggleClass('is-selected');
        });

        var bodyHeight = $("body").outerHeight();
        var bannerHeight = $(".banner").outerHeight();
        var footerHeight = $("footer").outerHeight();
        var containerMinHeight = bodyHeight - bannerHeight - footerHeight;
        $('.row-content-container').css({'min-height': containerMinHeight + 'px'});
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
